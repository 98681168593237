import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index.js";
Vue.use(VueRouter);

const VueRouterPush = VueRouter.prototype.push;
// 修改原型对象中的push函数
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};
const routes = [
  {
    path: "/",
    name: "homePage",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/login",
    name: "userLogin",
    component: () => import("@/views/Login.vue"), // Update the import statement to match the correct case
  },
  {
    path: "/gameDetail",
    name: "gameDetail",
    component: () => import("@/views/gameDetail.vue"), // Update the import statement to match the correct case
  },
  {
    path: "/buyOrder",
    name: "buyOrder",
    component: () => import("@/views/buyOrder.vue"), // Update the import statement to match the correct case
  },
  {
    path: "/sellerOrder",
    name: "sellerOrder",
    component: () => import("@/views/sellerOrder.vue"), // Update the import statement to match the correct case
  },
  {
    path: "/commodity",
    name: "commodityPage",
    component: () => import("@/views/commodity.vue"), // Update the import statement to match the correct case
  },
  {
    path: "/orderDetail",
    name: "orderDetail",
    component: () => import("@/views/orderDetail.vue"), // Update the import statement to match the correct case
  },
  {
    path: "/Suggest",
    name: "SuggestPage",
    component: () => import("@/views/suggest.vue"), // Update the import statement to match the correct case
  },
  {
    path: "/detailInfo",
    name: "detailInfo",
    component: () => import("@/views/detailInfo.vue"), // Update the import statement to match the correct case
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

const whiteList = ["userLogin", "homePage", "gameDetail"]; // 更新为你的实际路由名称

router.beforeEach((to, from, next) => {
  // 假设 `isLoggedIn` 是存储在 Vuex store 中的登录状态
  const isLoggedIn = store.state.user;
  if (!isLoggedIn && !whiteList.includes(to.name)) {
    // 如果用户未登录且要访问的不是登录页面，那么重定向到登录页面
    next({ name: "userLogin" });
  } else {
    // 否则，正常进行路由跳转
    next();
  }
});
export default router;
