import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: '', // 初始值
  },
  getters: {
    getUser: state => {
      return state.user;
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    clearUser(state) {
      state.user = null;
    }
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState({
    storage: window.localStorage,
  })],
});
