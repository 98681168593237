<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    // 页面加载时调用设置字体大小的函数
    this.setRootFontSize();
    // 窗口大小变化时调用设置字体大小的函数
    window.addEventListener('resize', this.setRootFontSize);
  },
  beforeDestroy() {
    // 组件销毁前移除窗口大小变化的事件监听器，避免内存泄漏
    window.removeEventListener('resize', this.setRootFontSize);
  },
  methods: {
    setRootFontSize() {
      // 获取窗口宽度
      const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      // 计算根元素的字体大小，这里可以根据需要自行调整计算方式
      let fontSize;
      if (width > 750) {
        fontSize = '75px'; // 最大值是750px
      } else {
        fontSize = width / 10 + 'px';
      }
      // 设置根元素的字体大小
      document.documentElement.style.fontSize = fontSize;
    }
  }
}
</script>
<style lang="less" scoped>
#app{
  width: 10rem;
  margin: 0 auto;
}
</style>
