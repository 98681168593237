import Vue from "vue";
import {
  Popup,
  Cell,
  CellGroup,
  Search,
  Toast,
  Grid,
  GridItem,
  Dialog,
  NavBar,
  Field,
  Icon,
  Checkbox,
  CheckboxGroup,
  Tab,
  Tabs,
  Uploader,
  List,
  PullRefresh
} from "vant";

Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Popup);
Vue.use(Icon);
Vue.use(Search);
Vue.use(Toast);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Dialog);
Vue.use(NavBar);
Vue.use(Field);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Uploader);
Vue.use(List);
Vue.use(PullRefresh);